.col {
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-content {
  width: 100%;
  flex: 0 fit-content;
}

@include col(24);
