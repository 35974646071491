*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*,
*:active,
*:focus {
  outline: none;
}

html,
body {
  min-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

* {
  font-family: 'Heebo', sans-serif;
  font-weight: 300;
  scrollbar-width: thin;
  scrollbar-color: $secondary-color transparent;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border: none;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $secondary-color;
    border-radius: $border-radius;
    height: 35%;
  }
}

.map-page {
  overflow: hidden;
  height: 100vh;
}

.map-grid-col {
  position: relative;
  width: 100%;
  height: 100%;

  @media (min-width: $breakpoint-xl) {
    grid-area: 1 / 1 / 3 / 2;
  }

  @media (min-width: $breakpoint-xxl) {
    grid-area: unset;
  }
}

.map-grid {
  display: grid;
  grid-template-rows: 1fr;
  height: calc(100vh - 75px);
  background-color: $dash-color;

  &.dash-open {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto auto;
  }

  @media (min-width: $breakpoint-xl) {
    &.dash-open {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr auto;
    }
  }

  @media (min-width: $breakpoint-xxl) {
    &.dash-open {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto auto;
    }
  }
}
