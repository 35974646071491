@import '/src/assets/scss/abstracts/variables';

.tabs {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 100%;
  left: 78px;

  @media (min-width: $breakpoint-xl) {
    bottom: 0;
    top: 44px;
    flex-direction: column;
    left: auto;
    right: 50vw;
    width: fit-content;
  }

  @media (min-width: $breakpoint-xxl) {
    flex-direction: row;
    bottom: 100%;
    left: 78px;
    top: auto;
  }
}

.tabs-list {
  display: flex;
  align-items: center;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  overflow: hidden;

  @media (min-width: $breakpoint-xl) {
    flex-direction: column;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
  }

  @media (min-width: $breakpoint-xxl) {
    flex-direction: row;
    border-top-right-radius: $border-radius;
    border-bottom-left-radius: 0;
  }
}

.tabs-item {
  @media (min-width: $breakpoint-xl) {
    width: 100%;
  }

  @media (min-width: $breakpoint-xxl) {
    width: auto;
  }
}

.tabs-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: $dash-color;
  border: none;
  color: $primary-color;
  font-size: 12px;
  font-weight: 300;
  height: 30px;
  min-width: 30px;
  padding: 5px 15px 0px 15px;
  width: 100%;

  &:hover:not(:disabled) {
    .tabs-list & {
      text-shadow: 0px 1px #fff;
    }
  }

  &.active {
    text-shadow: 0px 1px #fff;

    &::before {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $theme-color;
    }
  }

  &:focus {
    outline: none;
  }

  &:hover:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    color: $secondary-color;
  }

  &.single-btn {
    width: 30px;
    margin-left: 10px;
    padding: 5px 8px;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    @media (min-width: $breakpoint-xl) {
      width: 100%;
      margin-left: 0;
      margin-top: 10px;
      border-top-right-radius: 0;
      border-bottom-left-radius: $border-radius;
    }

    @media (min-width: $breakpoint-xxl) {
      width: 30px;
      margin-left: 10px;
      margin-top: 0;
      border-top-right-radius: $border-radius;
      border-bottom-left-radius: 0;
    }
  }
}

.tabs-icon.icon {
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  background-position: center;
}
