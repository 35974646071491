.hidden {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
  z-index: -2;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #ebebeb80;
  z-index: 100;
}

.full-height {
  display: flex;
  height: 100%;
  align-items: center;
}

.d-flex {
  display: flex;
  align-items: center;
}

.a-center {
  align-items: center;
}

.j-center {
  justify-content: center;
}
