@import '../../../assets/scss/abstracts/variables';

.notif {
  position: fixed;
  bottom: 36px;
  right: 0;
  background-color: $items-color;
  padding: 20px 75px 20px 23px;
  display: flex;
  z-index: 12;
  align-items: center;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
}

.notif-btn {
  position: absolute;
  top: 25px;
  right: 51px;
  background-color: transparent;
  border: none;
  width: 13px;
  height: 13px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.notif-title {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
  margin-bottom: 2px;
}

.notif-subtitle {
  display: block;
  font-size: 16px;
  font-weight: 300;
  color: $primary-color;
}

.notif-img.icon {
  width: 35px;
  height: 35px;
  margin-right: 21px;
}

.notif-icon.icon {
  width: 100%;
  height: 100%;
}
