@import '../../../../assets/scss/abstracts/variables';

.vehicle-dropdown {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-left: 7px;

  @media (min-width: $breakpoint-xxl) {
    margin: 0 5px;
  }

  .dropdown-btn {
    width: 100%;
    justify-content: space-between;
  }
}

.vehicle-dropdown-container {
  display: none;
  position: absolute;
  top: calc(100% + 22px);
  left: 0;
  z-index: 5;
  width: 270%;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  box-shadow: $box-shadow;

  &.active {
    display: block;
  }
}

.vehicle-dropdown-list {
  padding: 0 18px;
  max-height: 260px;
  overflow-y: auto;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background-color: #1d1d1d;
  }

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    border: none;
    background-color: #1d1d1d;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7500;
    height: 35%;
  }
}

.vehicle-dropdown-item {
  position: relative;
  display: block;
  padding: 18px 20px;
  background-color: $main-color;
  border-radius: $border-radius;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.vehicle-dropdpwn-title {
  display: block;
  font-size: 16px;
  font-weight: 300;
  color: $primary-color;
}

.vehicle-dropdpwn-check.icon {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 15px;
  right: 10px;
}
