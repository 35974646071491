@import '../../../../assets/scss/abstracts/variables';

.header {
  display: flex;
  align-items: center;
  height: 75px;
  background-color: #171717;
  padding: 0 40px;
  position: relative;
}

.header-list-item {
  cursor: pointer;
  border-left: 1px solid $border-color;
  padding: 0 20px;

  &:last-child {
    border-right: 1px solid $border-color;
  }

  &.active {
    border-bottom: 2px solid $theme-color;
  }

  &.disabled {
    cursor: default;
  }
}

.header-list-icon.icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  display: block;
  margin-right: 8px;
}

.header-list-text {
  display: block;
  font-size: 14px;
  color: $secondary-color;

  @media (min-width: $breakpoint-xxl) {
    font-size: 16px;
  }

  .header-list-item:hover:not(.disabled) &,
  .header-list-item.active & {
    color: $theme-color;
  }
}

.header-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
  }
}

.header-btn-icon.icon {
  width: 21px;
  display: block;
  margin: 10px;
}

.header-user {
  margin-left: 38px;
}

.header-user-icon.icon {
  width: 20px;
  display: block;
}

.header-dropdown {
  margin: 0 7px;
  padding: 4px 11px;
  height: 33px;
  display: flex;
  align-items: center;
  border: 1px solid #6c6c6c;
  border-radius: $border-radius;
  min-width: 150px;

  @media (min-width: $breakpoint-xxl) {
    min-width: 200px;
    margin: 0 5px;
  }

  .dropdown-btn {
    width: 100%;
    justify-content: space-between;
  }
}

.header-logout.icon {
  width: 25px;
  height: 25px;
  display: block;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
}

.header-logo-tactile {
  max-height: 28px;
  margin-right: 20px;

  @media (min-width: $breakpoint-xxl) {
    margin-right: 40px;
  }
}

.header-logo-img {
  max-height: 37px;
  margin-left: 15px;
  min-width: 65px;
  object-fit: contain;

  @media (min-width: $breakpoint-xxl) {
    margin-left: 61px;
  }
}

.header-center {
  margin: 0 15px;

  @media (min-width: $breakpoint-xxl) {
    margin: 0 auto;
  }
}

.header-vehicles {
  .dropdown-group {
    min-width: 100%;
    width: auto;
    max-width: unset;
    max-height: unset;
    overflow-y: visible;
    padding: 12px;
    background-color: $main-color;
  }

  .dropdown-item {
    background-color: $main-color;
    font-size: 12px;
    font-weight: 300;
    padding: 9px 12px 9px 0;
  }
}

.header-vehicles-container {
  background-color: $main-color;
  max-height: 230px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    border: none;
    border-radius: 0;
    background-color: $items-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7500;
    border-radius: 0;
  }
}

.header-dropdown-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
}

.header-title {
  display: block;
  font-size: 14px;
  font-weight: 300;
  color: $primary-color;
}

%header-rec-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $primary-color;
  font-size: 16px;
  font-weight: 300;
  padding: 4px 11px;
  color: $primary-color;
  text-transform: capitalize;
  background-color: transparent;
  cursor: pointer;
  margin-left: 10px;
  overflow: hidden;
  min-width: 100px;

  &:hover {
    color: $theme-color;
  }
}

.header-rec-stream {
  @extend %header-rec-btn;
  border-radius: 17px;
  margin-right: auto;

  &::before {
    content: '';
    display: block;
    min-width: 7px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #da4453;
    margin-right: 8px;
  }
}

.header-rec-save {
  @extend %header-rec-btn;
  border-radius: $border-radius;

  &::after {
    content: attr(data-recording);
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #ff7500;
    font-size: 12px;
    font-weight: bold;
    color: $primary-color;
    height: 17px;
    min-width: 17px;
  }
}

.header-rec-btn {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.list-toggler {
  display: flex;
  align-items: center;
  margin-left: auto;
  border: 1px solid #6c6c6c;
  border-radius: $border-radius;
  height: 33px;
  padding: 2px;
}

.list-toggler-btn {
  display: block;
  height: 100%;
  background-color: transparent;
  font-size: 14px;
  font-weight: 300;
  color: $primary-color;
  border: none;
  text-align: center;
  min-width: 88px;
  padding: 0 20px;
  cursor: pointer;

  &:hover:not(.active) {
    color: $primary-color;
  }

  &.active {
    color: $main-color;
    background-color: $primary-color;
  }

  &:first-child {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  &:last-child {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
