@import '../../../../../../assets/scss/abstracts/variables';

.wheel {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  grid-template-rows: repeat(2, auto);
  height: 100%;
  gap: 10%;

  .car-dash-mini & {
    gap: 0;
    width: 100%;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: unset;
  }
}

.wheel-title {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: $primary-color;
}

.wheel-wear {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 300;
  color: $primary-color;

  &.danger {
    color: #f4240e;
  }
}

.wheel-stiffness {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
}

.stiffness-value {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
  margin-left: 13px;

  &.danger {
    color: #f4240e;
  }
}

.wheel-car-container {
  position: relative;
  min-width: 120px;
  max-width: 130px;
  grid-area: 1 / 2 / 3 / 3;
  width: auto;
  margin: 0 auto;
  height: fit-content;

  @media (min-width: $breakpoint-xl) {
    min-width: 90px;
    max-width: 70%;
    height: fit-content;
  }

  @media (min-width: $breakpoint-xxl) {
    min-width: 120px;
    max-width: 150px;
  }
}

.wheel-car {
  height: 100%;
  width: 100%;
  object-fit: contain;

  @media (min-width: $breakpoint-xl) {
    max-height: 29vh;
  }

  @media (min-width: $breakpoint-xxl) {
    max-height: unset;
  }
}

.wheel-info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .car-dash-mini & {
    flex-direction: row;
    width: 50%;
  }
}

.wheel-warn-icon.icon {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.wheel-tire-container {
  display: flex;
  align-items: center;
  position: absolute;

  &.top {
    top: 26px;
  }

  &.bottom {
    bottom: 18px;
  }

  &.left {
    left: 5px;
    justify-content: left;
  }

  &.right {
    right: 5px;
    justify-content: right;
  }

  .car-dash-mini & {
    position: relative;
  }
}

.wheel-tire {
  width: 4vh;

  @media (min-width: $breakpoint-xxl) {
    width: 39px;
  }

  .car-dash-mini & {
    margin-left: 5px;
    margin-right: 15px;

    @media (min-width: $breakpoint-xxl) {
      margin-left: 10px;
      margin-right: 10%;
    }
  }
}

.wheel-shape {
  position: absolute;
  min-width: 39px;
  width: 2%;
  top: 43%;
  transform: translate(0, -50%);

  .left & {
    left: -47px;
  }

  .right & {
    right: -47px;
    transform: translate(0, -50%) rotate(180deg);
  }

  .car-dash-mini & {
    display: none;
  }
}

.wheel-indication {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  line-height: 18;
  text-transform: uppercase;
  color: $primary-color;
  width: 36px;
  min-width: 36px;
  height: 20px;
  border-radius: 20px;
  background-color: #378d51;
  margin-left: 10px;
}

.wheel-tire-angle {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
  color: $primary-color;
}
