@import '../../../../../assets/scss/abstracts/variables';

.rec-popup {
  position: fixed;
  top: 100px;
  right: 0;
  z-index: 12;
  box-shadow: $box-shadow;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  width: fit-content;
  background-color: #525252;
  padding: 5px 20px 5px 5px;
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: 22px;
}

.rec-popup-video {
  position: relative;
  width: 100%;
  height: 122px;
  width: 172px;
}

.rec-video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: $border-radius;
}

.rec-video-timesheet {
  position: absolute;
  left: 13px;
  top: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  color: $main-color;
  height: 20px;
  padding: 0 12px;
  background-color: $primary-color;
  border-radius: 10px;
}

.rec-title {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: $primary-color;

  &:not(:first-child) {
    margin-top: auto;
  }
}

.rec-name {
  display: block;
  font-size: 16px;
  font-weight: 300;
  color: $primary-color;
}

.rec-popup-info {
  display: flex;
  flex-direction: column;
  padding: 14px 0;

  .rec-dialog-bookmark & {
    margin-left: 22px;
  }
}

.rec-popup-controls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 17px 0;
}

.rec-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13px;
  height: 13px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &.delete {
    width: 17px;
    height: 17px;
  }
}

.rec-icon.icon {
  width: 100%;
}
