@each $name, $color in $colors-map {
  .btn-#{$name} {
    color: $color;
    background-color: transparent;
    border-color: $color;
  }
}

/*-------grid-------*/

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}

@mixin col($columns-count) {
  @for $i from 1 through ($columns-count) {
    .col-#{$i} {
      max-width: calc(($i * 100%) / $columns-count);
      flex: 0 0 calc(($i * 100%) / $columns-count);
    }
  }
  @each $point, $breakpoint in $breakpoints {
    .col-#{$point} {
      @media (min-width: $breakpoint) {
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
      }
    }
  }
  @each $point, $breakpoint in $breakpoints {
    @if ($point) {
      @for $i from 1 through ($columns-count) {
        .col-#{$point}-#{$i} {
          @media (min-width: $breakpoint) {
            max-width: calc(($i * 100%) / $columns-count);
            flex: 0 0 calc(($i * 100%) / $columns-count);
          }
        }
      }
    }
  }
}

@mixin offset($columns-count) {
  @for $i from 1 through ($columns-count) {
    .offset-#{$i} {
      margin-left: calc(($i * 100%) / $columns-count);
    }
  }
  @each $point, $breakpoint in $breakpoints {
    @if ($point) {
      @for $i from 1 through ($columns-count) {
        .offset-#{$point}-#{$i} {
          @media (min-width: $breakpoint) {
            margin-left: calc(($i * 100%) / $columns-count);
          }
        }
      }
    }
  }
}
