@import '../../../../../../assets/scss/abstracts/variables';

.speedometer {
  position: relative;
  width: 100%;
  width: 200px;
}

.speedometer-container {
  position: absolute;
  top: -4px;
  left: -4px;
  width: calc(100% + 8px);
}

.speedometer-flare {
  width: 115%;
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translate(-50%, 0);
  top: -20px;
}

.speedometer-light {
  width: 120%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: -22px;
}

.speedometer-shadow {
  position: absolute;
  bottom: 30%;
  left: 0;
  height: 30%;
  width: 100%;
  z-index: 10;
  background-color: #1e1e1e;
  opacity: 0.5;
  filter: blur(14px);
  transform: translate(0, -50%);
}

.speedometer-text {
  display: block;
  font-size: 24px;
  font-weight: 300;
  color: $primary-color;
  text-align: center;
  line-height: 18px;
  margin-bottom: 32px;

  &.unit {
    font-size: 12px;
  }
}

.speedometer-widget {
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: $breakpoint-xxl) {
    margin: 0 5%;
    align-items: center;
  }

  .car-dash-mini & {
    margin: 0;
  }

  .acc-speed-container & {
    margin: 0;
    align-items: flex-start;
  }
}

.speedometer-value-container {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.speedometer-value {
  display: block;
  position: absolute;
  font-size: 12px;
  color: $primary-color;
  height: fit-content;
  transform: translate(0, -50%);

  &:first-child {
    left: -15px;
    top: 50%;
  }

  &:nth-child(2) {
    left: 2px;
    top: 17%;
  }

  &:nth-child(3) {
    top: -7px;
    left: 28%;
  }

  &:nth-child(4) {
    top: -7px;
    right: 28%;
  }

  &:nth-child(5) {
    right: -6px;
    top: 17%;
  }

  &:last-child {
    right: -26px;
    top: 50%;
  }
}
