@import '../../../../assets/scss/abstracts/variables';

.recording {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background-color: $items-color;
  border-radius: $border-radius;
  padding: 52px 58px;
  min-width: 40%;
  z-index: 12;
}

.recording-close-icon.icon {
  width: 100%;
  height: 100%;
}

.recording-icon.icon {
  width: 32px;
  height: 32px;
  margin: 0 auto;
}

.recording-close {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 17px;
  height: 17px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.recording-title {
  display: block;
  font-size: 24px;
  font-weight: 300;
  color: $primary-color;
  margin-top: 9px;
  margin-bottom: 21px;
  width: 100%;
  text-align: center;
}

.recording-label {
  display: block;
  font-size: 14px;
  font-weight: 300;
  color: $primary-color;
  margin-bottom: 5px;
}

.recording-input {
  display: flex;
  align-items: center;
  border: 1px solid #6c6c6c;
  border-radius: $border-radius;
  padding: 0 22px;
  font-size: 16px;
  font-weight: 300;
  color: $primary-color;
  background-color: $items-color;
  height: 39px;
  width: 100%;

  &::placeholder {
    color: #5e5e5e;
  }

  &.file {
    margin-bottom: 0;
  }

  &.isIncorrect {
    border-color: $danger-color;
  }
}

.recording-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  background-color: transparent;
  border: 1px solid $primary-color;
  border-radius: $border-radius;
  font-size: 16px;
  font-weight: 300;
  color: $primary-color;
  padding: 0 10px;
  cursor: pointer;
  white-space: nowrap;

  &.fill {
    background-color: $primary-color;
    color: $items-color;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  &:not(:disabled):hover {
    color: $theme-color;
  }
}

.recording-file-container {
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  gap: 10px;
}

.recording-btn-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 26px;
}

.recording-form {
  margin-bottom: 15px;
}

.recording-error {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: $danger-color;
}

.recording-back {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $dash-color;
  opacity: 0.7;
}

.recording-popup-title {
  width: 60%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 26px;
}
