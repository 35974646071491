.mapboxgl-map {
  max-height: calc(100vh - 75px);
  position: absolute;
  width: 100% !important;
  height: 100% !important;
}

.mapboxgl-popup-content {
  background: transparent;
  box-shadow: unset;
  padding: 0;
  border-radius: 0;
}

.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup {
  z-index: 20;
  max-width: unset !important;
}
