@import '../../../../../../assets/scss/abstracts/variables';

.dash-card {
  padding: 15px 10px 2px 10px;
  background-image: linear-gradient(180deg, #1e1e1e, transparent 115%);
  width: 100%;
  border-radius: $border-radius;

  @media (min-width: $breakpoint-xl) {
    padding: 20px;
  }

  @media (min-width: $breakpoint-xxl) {
    padding: 16px 22px;
  }

  .car-dash-mini & {
    display: flex;
    justify-content: space-between;
    padding: 20px 16px;
  }
}

.dash-vehicle-panel,
.dash-graph-panel {
  .car-dash-mini & {
    padding: 20px;

    @media (min-width: $breakpoint-xxl) {
      padding: 20px 38px;
    }
  }
}

.dash-graph-panel {
  @media (min-width: $breakpoint-xl) {
    order: 1;
    min-width: 70%;
  }

  @media (min-width: $breakpoint-xxl) {
    order: unset;
    min-width: unset;
  }
}

.dash-convergence-panel {
  display: block;
  .car-dash-mini & {
    display: block;
  }

  @media (min-width: $breakpoint-xl) {
    order: 3;
    padding: 20px 16px;
  }

  @media (min-width: $breakpoint-xxl) {
    order: unset;
  }
}

.dash-tire-panel {
  @media (min-width: $breakpoint-xl) {
    order: 4;
  }

  @media (min-width: $breakpoint-xxl) {
    order: unset;
  }
}

.dash-vehicle-panel {
  width: 180px;
  height: 100%;
  padding: 0 20px;
  max-height: calc(55vh - 125px);
  background-color: #171717;
  background-image: unset;

  @media (min-width: $breakpoint-xl) {
    order: 2;
    background-color: unset;
    background-image: linear-gradient(180deg, #1e1e1e, transparent 115%);
    height: auto;
    width: 100%;
    max-height: unset;
    padding: 16px 22px;
  }

  @media (min-width: $breakpoint-xxl) {
    order: unset;
  }
}

.dash-acc-panel {
  background-image: unset;
  padding: 0;
}

.dash-card.col-content.dash-acc-panel {
  width: 180px;
  max-height: calc(55vh - 125px);
  background-color: $dash-color;
  padding: inherit;
  border-radius: 0;

  @media (min-width: $breakpoint-xl) {
    position: static;
    bottom: unset;
    right: unset;
    height: auto;
    padding: 0;
    background-color: unset;
    width: fit-content;
  }
}

.dash-vehicle-weight {
  @media (min-width: $breakpoint-xl) {
    order: 2;
  }

  @media (min-width: $breakpoint-xxl) {
    order: unset;
  }
}
