@import '../../../../../../assets/scss/abstracts/variables';

.weight-distribution-container {
  width: 100%;
  padding: 15px 10px 2px 10px;
  background-image: linear-gradient(180deg, #1e1e1e, transparent 115%);

  &:first-child {
    background-image: none;
    background-color: #1e1e1e;
  }

  @media (min-width: $breakpoint-xxl) {
    padding: 16px;
  }

  &.centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .widget-title {
    margin: 0;
  }
}

.acc-cog-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 30px;
  margin-top: 15px;
}

.acc-gravity {
  position: relative;
  min-height: 40px;

  @media (min-width: $breakpoint-xxl) {
    height: 68px;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-bottom: 1px dashed $theme-color;
    width: 100%;
  }
}

.acc-gravity-cog {
  display: block;
  height: 20px;
  width: 20px;
  left: -10px;
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4_2)'%3E%3Cpath d='M20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 -5.74026e-08 10 0L10 10H20Z' fill='black'/%3E%3Cpath d='M10 -4.37114e-07C8.68678 -3.79711e-07 7.38642 0.258657 6.17316 0.761204C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C2.00035 3.85752 1.26375 4.95991 0.761204 6.17317C0.258657 7.38642 -5.51919e-07 8.68678 -4.37114e-07 10L10 10L10 -4.37114e-07Z' fill='white'/%3E%3Cpath d='M10 20C11.3132 20 12.6136 19.7413 13.8268 19.2388C15.0401 18.7362 16.1425 17.9997 17.0711 17.0711C17.9997 16.1425 18.7362 15.0401 19.2388 13.8268C19.7413 12.6136 20 11.3132 20 10L10 10L10 20Z' fill='white'/%3E%3Cpath d='M8.74228e-07 10C7.59423e-07 11.3132 0.258658 12.6136 0.761205 13.8268C1.26375 15.0401 2.00035 16.1425 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20L10 10L8.74228e-07 10Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4_2'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  top: 50%;
  transform: translate(0, -50%);
  margin-left: 0%;
}

.acc-gravity-img {
  max-height: 70px;
  object-fit: contain;

  &:last-child {
    margin-left: auto;
  }
}

.wheel-load-panel {
  display: flex;
  color: $primary-color;
  margin: 5px;
  justify-content: space-evenly;
  font-size: 14px;
}
