@import '../../../assets/scss/abstracts/variables';

@mixin mainButtonStyle {
  display: block;
  padding: 10px 20px;
  margin: 0 auto;
  margin-top: 20px;
  cursor: pointer;
  background-color: #ff7500;
  color: $primary-color;
  font-size: 18px;
  border: none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  transition: opacity 0.5s ease-in-out;
  min-width: 146px;
  min-height: 47px;
  border-radius: 20px;

  &:hover {
    background-color: darken(#ff7500, 5);
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: #ff7500;
  }
}

@mixin operationResultStyle {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.update-password-page {
  width: 100vw;
  height: 100vh;
  background-image: url('../../../assets/img/login-back.png');
  background-size: cover;
  background-position: center;

  .update-password {
    background-color: $main-color;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    min-width: 555px;
    border-radius: 20px;
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;

    .update-password-title {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      color: $primary-color;
      margin-bottom: 20px;
    }

    .update-password-no-token-error {
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 20px;
      color: $danger-color;
      text-align: center;
    }

    .update-password-form {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      .form-group {
        display: block;
        position: relative;
        margin-bottom: 25px;

        .form-field {
          display: flex;
          align-items: center;
          padding: 6px 0;
          width: 100%;
          position: relative;
          border-bottom: 1px solid #6f706d;

          .update-password-icon.icon {
            width: 15px;
            height: 15px;
            margin-right: 10px;
          }

          .form-control {
            width: 100%;
            height: 100%;
            border: none;
            background-color: transparent;
            color: $primary-color;
            font-size: 16px;
            caret-color: $primary-color;

            &::placeholder {
              color: $primary-color;
              font-size: 16px;
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              -webkit-background-clip: text;
              -webkit-text-fill-color: $primary-color;
              caret-color: $primary-color;
            }

            &:disabled {
              opacity: 0.5;
            }
          }

          .form-label {
            display: none;
          }
        }
      }

      input[type="submit"] {
        @include mainButtonStyle;
      }

      .update-password-submit-loading {
        @include operationResultStyle;
        color: $primary-color;
      }

      .update-password-submit-error {
        @include operationResultStyle;
        color: $danger-color;
      }
    }

    .back-to-login-link {
      color: $primary-color;
      font-size: 14px;
      background: none;
      margin-top: 20px;
      align-self: start;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.password-updated {
    .update-password {
      .back-to-login-link {
        @include mainButtonStyle;
      }
    }
  }
}
