.bookmark-toast-list {
  position: absolute;
  right: 110px;
  top: 100px;
  width: 300px;
  max-height: 700px;
  overflow-y: scroll;
  z-index: 1;
  color: #eaeaea;

  .bookmark-toast {
    width: 100%;
    padding: 10px;
    background-color: #3e3e3e;
    margin-bottom: 20px;
  }
}
