@import '../../../../../assets/scss/abstracts/variables';

.statistics {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  background-color: $dash-color;
  position: fixed;
  bottom: calc(100% + 20px);
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: $border-radius;
  align-items: stretch;

  @media (min-width: $breakpoint-xl) {
    left: -40vw;
    bottom: 0;
    transform: unset;
  }

  @media (min-width: $breakpoint-xxl) {
    left: 50%;
    bottom: calc(100% + 20px);
    transform: translate(-50%, 0);
  }
}

.statistics-title {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: $primary-color;
  margin-right: 10px;
}

.statistics-block {
  background-image: linear-gradient(180deg, #1e1e1e, transparent 115%);
  padding: 9px 11px;
}

.statistics-subtitle {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
  margin-bottom: 2px;
}

.statistics-value {
  display: block;
  font-size: 24px;
  font-weight: 300;
  color: $theme-color;
}

.statistics-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:first-child {
    margin-right: 12px;
  }
}

.statistics-btn-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
