@import '../../../../../../assets/scss/abstracts/variables';

.accelerator-accelerometer {
  margin-left: 2px;
  margin-right: 2px;

  .widget-title {
    text-wrap: balance;
  }
}

.accelerator-measurement-values {
  border-right: 1px solid #3e3e3e;
  width: fit-content;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .accelerator-torque & {
    border-right: none;
    border-left: 1px solid #3e3e3e;
  }
}

.accelerator-measurement-value {
  display: block;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  color: $primary-color;
  width: fit-content;
  margin-right: 10px;

  .accelerator-torque & {
    margin-right: 0;
    margin-left: 10px;
  }
}

.accelerator-measurement-range {
  display: block;
  width: 4px;
  background-color: $primary-color;
  height: 25px;
  position: absolute;
  right: -2px;
  bottom: 67px;

  &.inRange {
    background-color: $theme-color;
  }

  .accelerator-torque & {
    left: -2px;
    right: unset;
    bottom: 1px;
  }
}

.accelerator-value-bar {
  display: block;
  width: 27px;
  height: 160px;
  background-color: $main-color;
  border-radius: $border-radius;
  margin-left: 10px;
  position: relative;

  &.torque-value-bar {
    margin-left: 0;
    margin-right: 10px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.accelerator-pointer {
  position: absolute;
  left: 0;
  width: 27px;
  height: 20px;
  background-color: $theme-color;
  opacity: 0.7;
  transition: all 0.5s ease;

  .torque-value-bar & {
    height: 14px;
    position: static;
    opacity: 1;
  }
}

.accelerator-torque {
  @media (min-width: $breakpoint-xxl) {
    margin-left: 12px;
  }

  .car-dash-mini & {
    margin-left: 0;
  }
}

$from-r: 244;
$from-g: 36;
$from-b: 14;
$to-r: 38;
$to-g: 163;
$to-b: 94;
$from: rgba($from-r, $from-g, $from-b, 1);
$to: rgba($to-r, $to-g, $to-b, 1);
$number: 8;

@function calcRgbaColor($index, $leftSide) {
  $add: $index + 1;
  @if $leftSide == true {
    $add: $index - 1;
  }
  @return rgba(
    $from-r + calc($add * ($to-r - $from-r) / $number),
    $from-g + calc($add * ($to-g - $from-g) / $number),
    $from-b + calc($add * ($to-b - $from-b) / $number),
    1
  );
}
@for $i from 1 through $number {
  .accelerator-value-bar.torque-value-bar > span:nth-child(#{$i}) {
    background-image: -webkit-linear-gradient(calcRgbaColor($i, true), calcRgbaColor($i, false));
    background-image: linear-gradient(calcRgbaColor($i, true), calcRgbaColor($i, false));
  }
}
