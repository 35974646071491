@import '../../../assets/scss/abstracts/variables';

.toggler {
  display: flex;
  align-items: center;
}

.toggler-text {
  display: block;
  font-size: 16px;
  color: $primary-color;
  margin-right: 9px;
  width: 17px;
}

.toggler-container {
  display: inline-block;
  position: relative;
  min-width: 28px;
  height: 15px;
}

.toggler-input {
  display: none;
}

.toggler-slider {
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.2s;
  border-radius: $border-radius;
  background-color: #6c6c6c;
  border: 1px solid #6c6c6c;

  &::before {
    background-color: $items-color;
    right: 1px;
    top: 0;
    content: '';
    position: absolute;
    transition: 0.2s;
    border-radius: $border-radius;
    width: 14px;
    height: 13px;
    transform: translate(0) translateX(-11px);

    .toggler-input:checked + & {
      transform: translate(0) translateX(1px);
    }
  }

  .toggler-container.isChecked & {
    background-color: $primary-color;
  }

  .toggler-input:disabled + & {
    opacity: 0.5;
    cursor: default;
  }
}
