@import '../../../../../../assets/scss/abstracts/variables';

.tire-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-width: 40px;
  object-fit: contain;
  margin: 0 auto;
  background-image: linear-gradient(0deg, #181818, #1f1f1f);
  border-radius: $border-radius;

  @media (min-width: $breakpoint-xxl) {
    min-width: 30px;
    max-width: 60px;
  }

  &.isActive {
    &::after {
      content: '';
      display: block;
      width: 40%;
      height: 40%;
      min-width: 15px;
      object-fit: contain;
      background-color: #208227;
      position: absolute;
      top: 52%;
      left: 52%;
      transform: translate(-50%, -50%);
      filter: blur(10px);
    }
  }

  &:not(:first-child) {
    margin-top: 20px;
  }

  .car-dash-mini & {
    margin-top: 0;
  }
}

.tire-wheel {
  display: block;
  width: 100%;
  min-width: 30px;
  max-width: 50px;
  object-fit: contain;
  margin-top: 10px;
  position: relative;
  z-index: 2;
  padding: 2px 5px;
  opacity: 0.5;

  .car-dash-mini & {
    margin-top: 0;
  }

  .isActive & {
    opacity: 1;
  }
}

.tire-multiwheel {
  display: block;
  width: 100%;
  min-width: 30px;
  max-width: 50px;
  z-index: 2;
  padding: 10px 5px;

  opacity: 0.5;

  .isActive & {
    opacity: 1;
  }
}
