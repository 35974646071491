@import '../../../assets/scss/abstracts/variables';

.forgot-password-page {
  width: 100vw;
  height: 100vh;
  background-image: url('../../../assets/img/login-back.png');
  background-size: cover;
  background-position: center;

  .forgot-password-form {
    background-color: $main-color;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    min-width: 555px;
    border-radius: 20px;
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;

    .forgot-password-explanation {
      font-size: 16px;
      margin-bottom: 20px;
      color: $primary-color;
    }

    .forgot-password-title {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      color: $primary-color;
      margin-bottom: 20px;
    }

    .form-group.email-input {
      display: block;
      margin-top: 12px;
      margin-bottom: 16px;
      position: relative;

      &:first-of-type {
        margin-bottom: 25px;
      }

      .form-field {
        display: flex;
        align-items: center;
        padding: 6px 0;
        width: 100%;
        position: relative;
        border-bottom: 1px solid #6f706d;

        .forgot-password-icon.icon {
          width: 15px;
          height: 15px;
          margin-right: 10px;
        }

        .form-control {
          width: 100%;
          height: 100%;
          border: none;
          background-color: transparent;
          color: $primary-color;
          font-size: 16px;
          caret-color: $primary-color;

          &::placeholder {
            color: $primary-color;
            font-size: 16px;
          }

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            -webkit-background-clip: text;
            -webkit-text-fill-color: $primary-color;
            caret-color: $primary-color;
          }

          &:disabled {
            opacity: 0.5;
          }
        }

        .form-label {
          display: none;
        }
      }
    }

    input[type="submit"] {
      display: block;
      padding: 10px 20px;
      margin: 0 auto;
      margin-top: 50px;
      cursor: pointer;
      background-color: #ff7500;
      color: $primary-color;
      font-size: 18px;
      border: none;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
      transition: opacity 0.5s ease-in-out;
      min-width: 146px;
      min-height: 47px;
      border-radius: 20px;

      &:hover {
        background-color: darken(#ff7500, 5);
      }

      &:disabled {
        opacity: 0.5;
        cursor: default;
        background-color: #ff7500;
      }
    }

    .forgot-password-submit-loading {
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 20px;
      color: $primary-color;
      text-align: center;
    }

    .forgot-password-submit-success {
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 20px;
      color: $primary-color;
      text-align: center;
    }

    .forgot-password-submit-error {
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 20px;
      color: $danger-color;
      text-align: center;
    }

    .back-to-login-link {
      color: $primary-color;
      font-size: 14px;
      background: none;
      margin-top: 20px;
      align-self: start;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

