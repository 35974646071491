@import '../../../../../../assets/scss/abstracts/variables';

.widget-title {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: $primary-color;
  white-space: nowrap;
  margin-bottom: 9px;

  .widget.speedometer-widget & {
    text-align: left;

    @media (min-width: $breakpoint-xxl) {
      text-align: center;
    }
  }

  .car-dash-mini .widget.speedometer-widget &,
  .acc-speed-container .widget.speedometer-widget & {
    text-align: left;
  }
}

.widget-value {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 300;
  color: $primary-color;
  line-height: 18px;
  white-space: nowrap;
  width: 96px;
  margin-bottom: 5px;
  width: fit-content;

  .value-small {
    font-size: 12px;
  }

  .car-dash-mini & {
    @media (min-width: $breakpoint-xxl) {
      margin-bottom: 8px;
    }
  }

  @media (min-width: $breakpoint-xxl) {
    margin-bottom: 36px;
    width: 90px;
  }
}

.widget-heading {
  .speedometer-widget & {
    text-align: center;
  }
}

.widget-body {
  display: flex;

  .speedometer-widget & {
    @media (min-width: $breakpoint-xxl) {
      margin-top: 38px;
    }
  }

  .acc-speed-container .speedometer-widget & {
    @media (min-width: $breakpoint-xxl) {
      margin-top: 15px;
    }
  }

  .car-dash-mini & {
    margin-top: 0;
  }
}

.widget {
  padding: 0.5rem 14px 0 14px;

  .dash-vehicle-panel & {
    &:first-child {
      padding-top: 1.5rem;

      @media (min-width: $breakpoint-xl) {
        padding-top: 0;
      }
    }
  }

  @media (min-width: $breakpoint-xl) {
    padding: 0;
  }
}

.widget-loading {
  padding-bottom: 30px;
}

.widget-loading-icon.icon {
  width: 27px;
  height: 29px;
  display: block;
  margin: 0 auto;
  margin-bottom: 9px;
  margin-top: 1px;
}

.widget-loading-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
}

.widget-loading-dots {
  display: flex;
  align-items: center;
}

.widget-loading-dot {
  display: block;
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  color: $primary-color;
  font-size: 16px;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:last-child {
    animation-delay: 0.4s;
  }
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
