@import '../../../../../../../assets/scss/abstracts/variables';

.separator {
  background-color: #ffffff80;
  width: 1px;
  margin-top: 10px;
  height: 20px;

  .separator-container:nth-child(even) & {
    height: 7px;
    margin-top: 24px;
  }
}
