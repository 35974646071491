@import '../../../../../assets/scss/abstracts/variables';

.acc-speed {
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
  gap: 10px;
  padding: 0;

  @media (min-width: $breakpoint-xxl) {
    gap: 20px;
  }
}

.acc-speed-container {
  width: 100%;
  padding: 15px 10px 2px 10px;
  background-image: linear-gradient(180deg, #1e1e1e, transparent 115%);

  &:first-child {
    background-image: none;
    background-color: #1e1e1e;
  }

  @media (min-width: $breakpoint-xxl) {
    padding: 16px;
  }

  &.centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
