@import '../../../../../../assets/scss/abstracts/variables';

.vehicle-weight-widget {
  width: 100%;
  padding: 15px 10px 2px 10px;
  background-color: #1e1e1e;

  @media (min-width: $breakpoint-xxl) {
    padding: 16px;
  }

  &.centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .weight-provider-selector {
    margin-left: auto;
  }

  .widget-title {
    margin: 0;
  }

  .weight-value {
    display: block;
    font-size: 24px;
    font-weight: 300;
    color: $primary-color;
  }

  .weight-unit {
    display: block;
    font-size: 12px;
    font-weight: 300;
    color: $primary-color;
  }

  .weight-subtitle {
    display: block;
    color: $primary-color;
    font-size: 12px;
    font-weight: 300;
    margin-right: 9px;
  }

  .weight {
    display: block;
    width: 100%;
    height: 18px;
    border-radius: $border-radius;
    position: relative;
  }

  .weight-fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: $border-radius;
    background-image: linear-gradient(90deg, $theme-color, transparent 115%);
  }

  .weight-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: calc(100% + 10px);
    left: -10px;
    width: calc(100% + 20px);
  }

  .weight-item {
    display: block;
    width: 18px;
    color: $primary-color;
    font-size: 12px;
    font-weight: 300;
    position: relative;

    &:not(:first-child):not(:last-child) {
      &::after {
        content: '';
        display: block;
        height: 18px;
        width: 1px;
        background-color: #1e1e1e;
        position: absolute;
        top: calc(100% + 10px);
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 1;
      }
    }
  }

  .weight-container {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 36px;
    margin-bottom: 5px;
    padding-right: 10px;

    @media (min-width: $breakpoint-xxl) {
      padding-right: 30px;
    }
  }

  .speed-icon.icon {
    width: 100%;
    height: 80px;
    margin: 0 auto;
  }
}
