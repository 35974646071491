@import '../../../../../../assets/scss/abstracts/variables';

.torque {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 10px;
  position: relative;

  @media (min-width: $breakpoint-xxl) {
    margin-right: 38px;
  }
}

.torque-back-gradient {
  width: 160px;
  background: linear-gradient(0deg, #86c1d0 0%, #95c8cb 20%, #acd2c2 40%, #d9e5b1 60%, #eed29d 80%, #d8ad8e 100%) 50%;
  position: relative;
  position: absolute;
  left: 2px;
  bottom: 94px;
  z-index: 1;
  transition: all 0.25s ease-in;
}

.torque-back {
  width: 160px;
  height: 236px;
  position: relative;
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translate(0, -50%);
  background-color: #3d5467;
}

.torque-img {
  width: 165px;
  height: 238px;
  object-fit: contain;
  z-index: 2;
}
