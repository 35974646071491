@import '../../../../assets/scss/abstracts/variables';

.rec-dialog-container {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 50%;
  z-index: 12;
  background-color: $items-color;
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-xxl) {
    width: 31%;
  }
}

.rec-dialog {
  padding: 0 18px;
}

.rec-dialog-header {
  padding: 48px 20px 0 20px;
}

.rec-dialog-subtitle {
  display: block;
  font-size: 24px;
  font-weight: 300;
  color: $primary-color;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 5px;
}

.rec-dialog-label {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: $primary-color;
  margin-bottom: 9px;
}

.rec-dialog-input {
  display: block;
  width: 100%;
  border: 1px solid #6c6c6c;
  border-radius: $border-radius;
  padding: 0 15px;
  height: 46px;
  font-size: 16px;
  font-weight: 300;
  color: $primary-color;
  background-color: transparent;
}

.rec-dialog-length {
  display: block;
  font-size: 16px;
  font-weight: 300;
  color: $primary-color;
  margin-top: 10px;
  margin-bottom: 25px;
}

.rec-dialog-bookmarks {
  overflow-y: auto;
  padding: 0 20px;
  max-height: calc(100vh - 270px);

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    border: none;
    border-radius: 0;
    background-color: #000;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7500;
    border-radius: 0;
  }
}

.rec-dialog-bookmark {
  display: grid;
  grid-template-columns: auto 1fr auto;
  box-shadow: $box-shadow;
  width: 100%;
  background-color: $main-color;
  border-radius: $border-radius;
  padding: 5px 20px 5px 5px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.rec-dialog-icon.icon {
  display: block;
  width: 16px;
  height: 16px;
}

.rec-dialog-form {
  display: flex;
  align-items: center;
  width: 100%;
}

.rec-dialog-btn {
  display: block;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: none;
  margin-left: 15px;
  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }

  &.delete {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.rec-dialog-title {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.rec-dialog-name {
  display: block;
  font-size: 16px;
  font-weight: 300;
  color: $primary-color;
}

.rec-dialog-confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  color: $items-color;
  text-transform: capitalize;
  padding: 15px;
  height: 50px;
  margin-top: auto;
  background-color: $primary-color;
  border: none;
  cursor: pointer;

  &:hover {
    color: $theme-color;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}
