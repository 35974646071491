@import '../../../assets/scss/abstracts/_variables.scss';

.switch-toggle {
  position: relative;
  display: inline-block;
  min-width: 40px;
  width: 40px;
  height: 15px;
  margin-left: 20px;
}

.switch-checkbox {
  display: none;
}

.switch {
  position: absolute;
  cursor: pointer;
  background-color: #5d5d5d;
  border-radius: 20px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: -3px;
    width: 20px;
    height: 20px;
    background-color: #444444;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='45.975px' height='45.975px' viewBox='0 0 45.975 45.975' style='enable-background:new 0 0 45.975 45.975;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath fill='%23fff' d='M13.987,0c-2.762,0-5,2.239-5,5v35.975c0,2.763,2.238,5,5,5s5-2.238,5-5V5C18.987,2.238,16.75,0,13.987,0z'/%3E%3Cpath fill='%23fff' d='M31.987,0c-2.762,0-5,2.239-5,5v35.975c0,2.762,2.238,5,5,5s5-2.238,5-5V5C36.987,2.239,34.749,0,31.987,0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
}

.switch-checkbox:checked + .switch::before {
  transform: translateX(20px);
  background-color: $primary-color;
}

.switch-checkbox:checked + .switch::before {
  background-size: 14px;
  background-image: url("data:image/svg+xml,%3Csvg fill='none' height='512' viewBox='0 0 24 24' width='512' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-rule='evenodd' fill='rgb(0,0,0)' fill-rule='evenodd'%3E%3Cpath fill='%23000' d='m11.1464 1.85355c.315-.31498.8536-.0919.8536.35356v7.58578c0 .44541-.5386.66851-.8536.35351l-3.79285-3.79285c-.19526-.19526-.19526-.51184 0-.7071z'/%3E%3Cpath fill='%23000' d='m11 6c0-.55228.4477-1 1-1 4.4183 0 8 3.58172 8 8 0 4.4183-3.5817 8-8 8-4.41828 0-8-3.5817-8-8 0-.5523.44772-1 1-1s1 .4477 1 1c0 3.3137 2.68629 6 6 6 3.3137 0 6-2.6863 6-6 0-3.31371-2.6863-6-6-6-.5523 0-1-.44772-1-1z'/%3E%3C/g%3E%3C/svg%3E");
}
