@import '../../../../../../assets/scss/abstracts/variables';

.grip-title-container {
  .car-dash-mini & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-right: 10px;
  }
}

.grip-title {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: $primary-color;
}

.grip-subtitle {
  display: block;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 26px;
  color: $primary-color;

  .car-dash-mini & {
    margin-bottom: 0;
    white-space: normal;
    word-break: break-word;
  }

  .value-small {
    font-size: 12px;
  }
}

.grip-container {
  margin-left: 33px;
}

.grip-rolling {
  margin: 20px 33px;
}

.grip-dash-container {
  position: relative;
  margin: 0 auto;
}

.grip-dash-val {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
  padding: 7px 8px;
  background-color: $items-color;
  border-radius: $border-radius;
  width: fit-content;
  white-space: nowrap;
  text-align: center;
  position: absolute;
  top: -32px;
  left: 50%;
  transform: translate(-50%, 0);
}

.grip-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: -22px;
  left: -7px;
  width: calc(100% + 14px);
}

.grip-line-val {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
  width: 15px;
  text-align: center;
  white-space: nowrap;
}

.grip-vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  bottom: -7px;
  left: -32px;
  height: calc(100% + 14px);
}

.grip-area {
  position: absolute;
  top: 0;
  left: 0;
}
