.map-tooltip {
  padding: 20px;
  background-color: #262626;
  width: 315px;
}

.map-tooltip-icon.icon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.map-tooltip-title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 8px;
}

.map-tooltip-container {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-row-gap: 5px;
  column-gap: 10px;
}

.map-tooltip-label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #737b88;
}

.map-tooltip-value {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  text-align: right;
}

.map-tooltip-pin {
  display: block;
  position: absolute;
  top: 18px;
  right: 20px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.map-pin-icon.icon {
  width: 100%;
  height: 100%;
}
