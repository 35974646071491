.dropdown {
  position: relative;

  &.isDisabled {
    opacity: 0.5;
  }
}

.dropdown-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  .dropdown.isDisabled & {
    cursor: default;
  }

  &:focus {
    outline: none;
  }
}

.dropdown-btn-icon {
  width: 11px;
  height: 11px;
  display: block;
  margin-left: 9px;

  .dropdown.active & {
    transform: rotate(270deg);
  }
}

.dropdown-text {
  font-size: 14px;
  font-weight: 300;
  color: $primary-color;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dropdown-group {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 11;
  width: 100%;
  margin-top: 10px;
  max-height: 230px;
  min-width: 200px;
  overflow-y: auto;
  border-radius: $border-radius;
  box-shadow: $box-shadow;

  .header-dropdown & {
    width: calc(200% + 16px);
    margin-top: 21px;
    max-height: unset;
    border-radius: $border-radius;
    overflow: hidden;

    @media (min-width: $breakpoint-xxl) {
      width: 393px;
    }
  }

  .header-dropdown.content & {
    width: 100%;
    min-width: unset;
  }

  .header-dropdown:first-child & {
    left: 0;
  }

  .header-dropdown:last-child & {
    right: 0;
  }

  &.active {
    display: block;
  }
}

.dropdown-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
  position: relative;
  color: $primary-color;
  background-color: #282828;

  &:hover {
    color: $theme-color;
  }
}

.dropdown-search {
  display: block;
  position: relative;
  margin-bottom: 18px;
}

.dropdown-search-input {
  border: 1px solid #6c6c6c;
  width: 100%;
  height: 31px;
  border-radius: $border-radius;
  padding: 10px 35px 9px 15px;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
  background-color: transparent;

  &::placeholder {
    color: #5e5e5e;
  }
}

.dropdown-search-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);
  width: 15px;
  height: 15px;
}

.dropdown-select {
  display: flex;
  align-items: center;
}

.dropdown-select-input:checked + .dropdown-record-title {
  &::before {
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%23fd7500' fill-rule='evenodd' d='M1.499 0H6.5C7.328 0 8 .671 8 1.499V6.5C8 7.328 7.328 8 6.5 8H1.499C.671 8 0 7.328 0 6.5V1.499C0 .671.671 0 1.499 0z'/%3E%3C/svg%3E%0A");

    .dropdown-list-container.isDisabled & {
      background-image: unset;
    }
  }
}

.dropdown-select-btn {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: #fff;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.dropdown-record-title {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: $theme-color;
  }

  .dropdown-record-item & {
    padding-left: 26px;
  }

  .dropdown-record-item:hover &,
  .dropdown-list-title:hover & {
    color: $theme-color;
  }

  .dropdown-list-container.isDisabled & {
    color: #5e5e5e;

    &::before {
      border-color: #5e5e5e;
    }
  }

  .dropdown-list-container.isActive .dropdown-list-title:first-of-type & {
    &::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 15px;
      left: 9px;
      top: 100%;
      border-left: 1px solid #5e5e5e;
    }
  }

  .dropdown-list & {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      border: 1px solid #6c6c6c;
      border-radius: $border-radius;
      display: block;
      width: 18px;
      min-width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }
}

.dropdown-notif {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    color: $theme-color;
  }

  &:focus {
    outline: none;
  }
}

.dropdown-settings {
  background-color: $items-color;
  padding: 18px;
  padding-left: 0;
}

.dropdown-settings-header {
  padding: 18px 19px;
  margin-bottom: 1px;
  background-color: $items-color;
}

.dropdown-settings-body {
  padding: 0 18px;
  max-height: 300px;
  overflow-y: auto;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background-color: #1d1d1d;
  }

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    border: none;
    background-color: #1d1d1d;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7500;
    height: 35%;
  }
}

.dropdown-list-container {
  background-color: $main-color;
  border-radius: $border-radius;
  padding: 9px 15px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.dropdown-list {
  margin-left: 42px;
}

.dropdown-record-item {
  display: block;
  position: relative;
  padding-left: 10px;
  padding-top: 7px;
}

.dropdown-list-title {
  display: grid;
  grid-template-columns: 0.7fr 1.3fr;
  justify-content: space-between;
  gap: 6px;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
  width: -webkit-fill-available;

  &.subtitle {
    margin-bottom: 10px;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}

.dropdown-list-btn {
  display: block;
  min-width: 18px;
  width: 18px;
  height: 18px;
  background-color: transparent;
  border: 1px solid $primary-color;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  margin-right: 15px;

  &:disabled {
    border-color: #5e5e5e;

    &::after {
      color: #5e5e5e;
    }
  }

  &::after {
    content: '+';
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $primary-color;
  }

  &:focus {
    outline: none;
  }

  &.isActive {
    border-color: #ff7500;

    &::after {
      content: '-';
      color: #ff7500;
    }
  }
}

.dropdown-section-title {
  font-size: 12px;
  font-weight: 300;
  display: block;
  color: $primary-color;
  margin-top: 20px;
  margin-bottom: 10px;
}

.dropdown-footer {
  margin-top: 5px;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  gap: 5px;
}

.dropdown-footer-item {
  display: flex;
  align-items: center;
  height: 27px;
  padding: 9px;
  background-color: $primary-color;

  &:first-child {
    border-bottom-left-radius: $border-radius;
  }

  &:last-child {
    border-bottom-right-radius: $border-radius;
  }
}

.dropdown-footer-btn {
  font-size: 12px;
  font-weight: bold;
  border: none;
  background-color: $primary-color;
  cursor: pointer;

  .dropdown-footer-item & {
    width: 50%;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:focus {
    outline: none;
  }

  &:not(:disabled):hover {
    color: $theme-color;
  }
}

.dropdown-pages {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.dropdown-pages-btn {
  width: 20px;
  height: 20px;
  padding: 2px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

.dropdown-pages-value {
  min-width: 20px;
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: $items-color;
  text-align: center;
}

.dropdown-pages-icon.icon {
  width: 100%;
  height: 100%;

  .dropdown-pages-btn.prev & {
    transform: rotate(180deg);
  }
}

.dropdown-list-upload {
  border-radius: $border-radius;
  min-width: 100px;
  margin-left: auto;
  width: fit-content;
}

.dropdown-speed {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: $items-color;
  padding: 0 9px;
}

.dropdown-pages-input {
  width: 20px;
  height: 16px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #262626;
}

.popup-arrow {
  color: $items-color;
}
