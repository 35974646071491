@import '../../../../assets/scss/abstracts/variables';

.playbar-wrapper {
  width: 100%;
  bottom: 0;
  z-index: 11;
  background-color: $items-color;
  padding: 5px 40px;

  .dash-open & {
    grid-area: 3 / 1 / 4 / 3;

    @media (min-width: $breakpoint-xl) {
      grid-area: unset;
    }
  }

  @media (min-width: $breakpoint-xl) {
    padding: 15px 40px;
  }
}

.playbar-bar {
  display: flex;
  align-items: center;
  width: 100%;
}

.playbar-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.playbar-btn-play {
    width: 30px;
    height: 30px;
  }

  &.playbar-btn-rewind {
    width: 12px;
    height: 12px;
  }

  &.playbar-btn-share {
    margin-left: 30px;
  }
}

.playbar-btn-rec {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 300;
  color: $primary-color;
  padding: 9px 15px;
  border: 1px solid $primary-color;
  border-radius: $border-radius;
  background-color: transparent;
  cursor: pointer;
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  &::before {
    content: '';
    display: block;
    min-width: 6px;
    height: 6px;
    background-color: #da4453;
    margin-right: 5px;
  }

  &.start {
    &::before {
      border-radius: 50%;
    }
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.playbar-actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.playbar-controls {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-left: 10px;
}

.playbar-bookmarks-popup {
   position: absolute;
   bottom: 40px;

  .rec-dialog-bookmark {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;

    .rec-popup-info {
      margin-right: 20px;
    }
  }
}

.playbar-icon.icon {
  display: block;
  width: 100%;
  height: 100%;
}

.playbar-timeline {
  display: block;
  position: relative;
  width: 75%;
  height: 11px;
  border-radius: 5px;
  background-color: #818181;
  box-shadow: rgba(0, 0, 0, 0.59);
  margin: 0 30px;
}

.playbar-timeline-fill {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 11px;
  background-color: $primary-color;
  border-radius: 5px;
  transition: all linear 1s;
}

.playbar-time {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: $primary-color;
  white-space: nowrap;
  margin-right: auto;
}

.playbar-btn-sub {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  background-color: $theme-color;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  color: $primary-color;
  position: absolute;
  bottom: -2px;
  right: -10px;
}

.playbar-btn-sup.icon {
  display: block;
  position: absolute;
  top: 0;
  right: -26px;
  width: 10px;
  height: 10px;
}
