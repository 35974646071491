@import '../../../../../../assets/scss/abstracts/variables';

.rolling-radius-indicator {
  width: 180px;
}

.rolling-radius-title {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}

.rolling-radius-value {
  display: block;
  font-size: 24px;
  font-weight: 300;

  .rolling-indicator-fl & {
    color: rgb(255, 247, 0)
  }

  .rolling-indicator-fr & {
    color: rgb(64, 160, 220)
  }

  .rolling-indicator-rl & {
    color: rgb(251, 116, 0)
  }

  .rolling-indicator-rr & {
    color: rgb(55, 141, 81)
  }
}

.rolling-radius-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.roll-radii-tab {
  position: relative;
}

.rolling-radius-graph {
  .grip-dash-container {
    .grip-vertical {
      .grip-line-val {
        direction: rtl;
        width: 28px;
      }
    }

    .grip-line {
      .grip-line-val {
        width: 15px;
      }
    }
  }
}

.radius-options {
  position: absolute;
  top: 16px;
  right: 22px;
  display: flex;
  align-items: center;

  .radius-type-toggler {
    margin-right: 15px;

    .toggler-text {
      margin-right: 32px;
    }
  }

  .radius-provider-toggler {
    .toggler-text {
      margin-right: 13px;
    }
  }

  .toggler-text {
    font-size: 12px;
  }

  .toggler-slider {
    background-color: $primary-color;
  }
}
