@import '../../../assets/scss/abstracts/variables';

$radioSize: 16px;

.tm-radio-group {
  display: flex;
  align-items: center;

  .tm-radio-group-button {
    display: flex;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;

    &:first-child {
      margin-left: 0px;
    }

    input[type="radio"] {
      margin-right: 5px;

      appearance: none;
      width: $radioSize;
      height: $radioSize;
      border-radius: 50%;
      background-color: $items-color;
      border-width: 2px;
      border-style: solid;
      border-color: $primary-color;
      outline: none;
      cursor: inherit;
      pointer-events: none;

      &:checked {
        background-color: $primary-color;
      }

      & + label {
        margin-top: 1px;
        font-size: 12px;
        color: $primary-color;
        cursor: inherit;
      }
    }
  }
}
